@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  outline: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  background: #3c3941;
  margin: 0;
}

input,
textarea {
  max-width: 100%;
  width: 100%;
  border: 0;
  height: 32px;
  padding: 0 10px;
}

.ie #homepage {
  padding-top: 60px;
}

.ie footer {
  padding: 10px 0;
}

.MuiFormGroup-root {
  flex-direction: row !important;
}

.MuiFormControlLabel-root {
  flex-basis: 50%;
  margin-right: 0 !important;
  margin-left: -5px !important;
}

.inputWrapper .MuiInput-root {
  margin-right: 10px !important;
}

.MuiFormControlLabel-root > span {
  padding: 4px !important;
}

.MuiRadio-root {
  color: #4a4d57 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #ff6200 !important;
}

.MuiTypography-body1 {
  margin-top: 3px !important;
  font-size: 14px !important;
}

.MuiInputBase-root {
  background: #fff;
  padding: 0 0 0 10px;
  padding-right: 10px;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
  padding: 6px 0 4px !important;
}

@media only screen and (max-width: 768px) {
  .MuiInputBase-input {
    padding: 3px 0 2px !important;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}

.inputWrapper > div {
  flex-grow: 1;
}

label,
input[type="radio"] {
  cursor: pointer;
}

.ReactModalPortal .ril__caption,
.ReactModalPortal .ril__toolbar {
  background: none;
}

.react-responsive-modal-closeButton path {
  fill: #fff !important;
}

@media only screen and (min-width: 1400px) {
  .ReactModalPortal .ril__image {
    max-width: 80%;
  }
}

.App .slick-prev::before,
.App .slick-next::before {
  color: #000;
}

@media print {
  @page {
    margin: 1cm;
  }
}

@media print {
  html,
  body {
    height: 99%;
  }
}
